export default [
    {
        heading: 'Features',
        id: 1,
        url: `${ process.env.NEXT_PUBLIC_APP_URL }/product/features/`,
        url_title: 'Time Tracking Features of Traqq',
        items: [
            {
                id: 1,
                link: '/time-tracking',
                title: 'Time tracking'
            },
            {
                id: 2,
                link: '/employee-monitoring',
                title: 'Employee monitoring'
            },
            {
                id: 3,
                link: '/team-management',
                title: 'Team management'
            },
            {
                id: 4,
                link: '/team-reporting',
                title: 'Team analysis and reporting'
            },
            {
                id: 5,
                link: '/attendance-tracking',
                title: 'Attendance tracking'
            },
            {
                id: 6,
                link: `${ process.env.NEXT_PUBLIC_APP_URL }/product/productivity-tracker/`,
                title: 'Productivity tracking'
            },
            {
                id: 7,
                link: '/updates',
                title: 'Product updates'
            }
        ]
    },
    {
        heading: 'Use Cases',
        id: 2,
        items: [
            {
                id: 1,
                link: `${ process.env.NEXT_PUBLIC_APP_URL }/product/enterprise-time-tracking-software/`,
                title: 'Enterprises'
            },
            {
                id: 2,
                link: `${ process.env.NEXT_PUBLIC_APP_URL }/product/time-tracking-app-for-small-businesses/`,
                title: 'Businesses'
            },
            {
                id: 3,
                link: '/time-tracking-app-for-agencies',
                title: 'Agencies'
            },
            {
                id: 4,
                link: '/time-tracking-app-for-teams',
                title: 'Teams'
            },
            {
                id: 5,
                link: `${ process.env.NEXT_PUBLIC_APP_URL }/product/time-tracking-software-for-freelancers/`,
                title: 'Freelancers'
            }
        ]
    },
    {
        heading: 'Help Center',
        id: 3,
        items: [
            {
                id: 1,
                link: '/support/getting-started',
                title: 'Getting started'
            },
            {
                id: 2,
                link: '/support/desktop-app',
                title: 'Desktop app'
            },
            {
                id: 3,
                link: '/support/tracking-time',
                title: 'Tracking time'
            },
            {
                id: 4,
                link: '/support/monitoring-teams',
                title: 'Monitoring teams'
            },
            {
                id: 5,
                link: '/support/managing-organization',
                title: 'Managing organization'
            },
            {
                id: 6,
                link: '/support/reports',
                title: 'Reports'
            },
            {
                id: 7,
                link: '/support/faq',
                title: 'FAQ'
            }
        ]
    },
    {
        heading: 'Company',
        id: 4,
        items: [
            {
                id: 1,
                link: '/about',
                title: 'About us'
            },
            {
                id: 2,
                link: '/contact',
                title: 'Contact us'
            },
            {
                id: 3,
                link: `${ process.env.NEXT_PUBLIC_BLOG_URL }/`,
                title: 'Blog'
            },
            {
                id: 4,
                link: '/scientific-collaboration-program',
                title: 'Scientific collaboration program'
            }
        ]
    },
    {
        heading: 'Why Traqq',
        id: 5,
        items: [
            {
                id: 1,
                link: '/toggl-alternatives',
                title: 'Traqq vs Toggl'
            },
            {
                id: 2,
                link: '/clockify-alternatives',
                title: 'Traqq vs Clockify'
            },
            {
                id: 3,
                link: '/desktime-alternatives',
                title: 'Traqq vs DeskTime'
            },
            {
                id: 4,
                link: '/timecamp-alternatives',
                title: 'Traqq vs TimeCamp'
            },
            {
                id: 5,
                link: '/hubstaff-alternatives',
                title: 'Traqq vs Hubstaff'
            },
            {
                id: 6,
                link: '/myhours-alternatives',
                title: 'Traqq vs MyHours'
            },
            {
                id: 7,
                link: '/activtrak-alternatives',
                title: 'Traqq vs ActivTrak'
            },
            {
                id: 8,
                link: '/trackingtime-alternatives',
                title: 'Traqq vs TrackingTime'
            },
            {
                id: 9,
                link: '/tmetric-alternatives',
                title: 'Traqq vs TMetric'
            },
            {
                id: 10,
                link: '/timedoctor-alternatives',
                title: 'Traqq vs Time Doctor'
            },
            {
                id: 11,
                link: '/harvest-alternatives',
                title: 'Traqq vs Harvest'
            },
            {
                id: 12,
                link: '/actitime-alternatives',
                title: 'Traqq vs actiTIME'
            }
        ]
    }
];
